import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN } from "../components/seo";
import { translations } from "../locales/companies.js";
import EventsTypes from "../components/eventsTypes";
import companies from "../assets/img/iconAdvantage/company.svg";
const Companies = () => {
  return (
    <Layout page="companies" language="en">
      <main className=" container">
        <EventsTypes
          background={companies}
          isBusiness={true}
          text={translations.en.text}
          title={translations.en.title}
          textButton={translations.en.button}
        />
      </main>
    </Layout>
  );
};

export default Companies;
export const Head = () => (
  <SeoEN
    title="Digital Invitations for Corporate Events | Design and send your invitations with Kolibird"
    description="With Kolibird, create and send your corporate invitations digitally. Customize your designs and surprise your guests with an ecological and practical alternative. Register now in Kolibird and start designing your invitations for corporate events."
    hrefLangs={[
      { href: "https://kolibird.app/empresas/", lang: "es" },
      { href: "https://kolibird.app/en/companies/", lang: "en" },
    ]}
  />
);
